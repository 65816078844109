import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import {
  MainContainer,
  PageContainer,
  SkewMainContainer,
  TopSkewBgContainer,
} from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import { WhiteBackGround } from "../components/NormalBackground";
import { PageTitle } from "../components/Title";
import { HelpView } from "../Views/contactViews/HelpView";
import { FormButton } from "./contact";

const ContactSuccessPage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <TopSkewBgContainer>
        <WhiteBackGround />
      </TopSkewBgContainer>
      <MainContainer className="mb-4 pt-28 pb-16">
        <PageTitle className="form-text">{t("表格提交成功")}</PageTitle>
        <p className="text-gray-500 mt-12">
          {t("非常感谢，我们已经收到需求表格，销售人员将会第一时间与您联系。")}
        </p>
        <div className="w-60 mt-16">
          <FormButton onClick={() => navigate("/")}>{t("返回首页")}</FormButton>
        </div>
      </MainContainer>
      <SkewMainContainer fillClassName="form-bg">
        <HelpView />
      </SkewMainContainer>
      <Footer />
    </PageContainer>
  );
};
export default ContactSuccessPage;

export const query = graphql`
  query ContactSuccessPage($language: String!) {
    ...AllLangauages
  }
`;
